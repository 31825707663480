$normal-weight: 400;
$bold-weight: 700;

@font-face {
    font-family: 'Splunk Data Sans Pro';
    src: url("../fonts/SplunkDataSansPro_W_Rg.woff2") format("woff2");
    font-weight: $normal-weight; }

@font-face {
    font-family: 'Splunk Data Sans Pro';
    src: url("../fonts/SplunkDataSansPro_W_Bd.woff2") format("woff2");
    font-weight: $bold-weight; }


body {
    font-family: 'Splunk Data Sans Pro', Arial; }

p {
    font-weight: $normal-weight; }
b, strong {
    font-weight: $bold-weight; }
