.tl-timeline {
  font-family: var(--font);
  background-color: var(--bg);
  color: var(--text);

  h1, h2, h3, h4, h5, h6 {
    color: var(--text); }

  .tl-menubar {
    background-color: var(--bg); }

  .tl-menubar-button {
    color: var(--text); }

  .tl-timemarker-content-container {
    background-color: var(--bg);
    color: var(--text); }

  .tl-headline-date, .tl-headline {
    color: var(--text); }

  .tl-text-content-container {
    background-color: var(--bg);
    color: var(--text); }

  .tl-slide-content-container {
    background-color: var(--bg);
    color: var(--text); }

  .tl-timemarker.tl-timemarker-active .tl-timemarker-content-container {
    background-color: var(--theme);
    color: var(--light); }

  .tl-slidenav-next, .tl-slidenav-previous {
    color: var(--text); }

  .tl-slidenav-next:hover, .tl-slidenav-previous:hover {
    color: var(--theme); }

  .tl-slidenav-title {
    color: var(--theme);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); }

  .tl-slidenav-description {
    color: var(--theme); }

  .tl-timeaxis-background {
    background-color: var(--bg); }

  .tl-timeaxis-minor .tl-timeaxis-tick,
  .tl-timeaxis-major .tl-timeaxis-tick {
    border-color: var(--text); }

  .tl-timeaxis-tick-text {
    color: var(--text); }

  .tl-attribution {
    display: none; } }


