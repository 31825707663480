.aside.menu {
  background-color: var(--accent);
  border-right: 1px solid var(--border-color);
  padding: 1rem;
  .section_title {
    margin-bottom: 1rem; } }

.section {
  &_title {
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--theme);
    border-bottom: 2px solid var(--theme);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem; }
  &_link {
    font-size: 1rem;
    font-weight: 400; } }

.sidebar, .sidenav {
  &-link {
    display: grid;
    padding: 0.2rem 0; } }

.section_link {
  font-size: 1rem;
  font-weight: 400;
  transition: all 0.3s ease;

  a {
    display: block;
    padding: 0.25rem 0.5rem;
    white-space: normal;
    overflow: visible;
    word-wrap: break-word; }

  &:hover, &.active {
    background-color: var(--accent);

    a {
      color: var(--theme); } }

  &:hover:not(.active) {
    background-color: var(--accent);
    color: var(--theme);
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem; } }

.filter-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; }

.form_search {
  flex-grow: 1;
  margin-right: 0.5rem; }

.filter-count {
  display: inline-block;
  padding: 0.2em 0.6em;
  border-radius: 1em;
  font-size: 0.9em;
  font-weight: 600;
  background-color: rgba(var(--theme-rgb), 0.1);
  color: var(--theme); }

.toc {
  padding: 0;
  margin: 1rem 0;
  height: auto;
  overflow: visible;
  display: block;
  ul {
    list-style-type: none;
    padding-left: 0.5rem; }
  li {
    font-size: 0.85rem;
    padding: 0.1rem 0;
    margin-bottom: 0.3rem; }
  a {
    color: var(--text);
    text-decoration: none;
    display: block;
    padding: 0.2rem 0.4rem;
    border-left: 2px solid transparent;
    transition: all 0.3s ease;
    &:hover {
      color: var(--theme);
      background-color: var(--accent);
      border-left-color: var(--theme); } }
  > ul > li > a {
    font-weight: 600;
    font-size: 0.9rem;
    margin-top: 0.8rem;
    margin-bottom: 0.4rem;
    border-bottom: 1px solid var(--accent); } }

.search {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
  &_field {
    padding: 0.5rem 1.5rem 0.5rem 2.5rem;
    border-radius: 1.5rem;
    width: 13.5rem;
    outline: none;
    border: 1px solid var(--search-border-color);
    background: transparent;
    color: var(--text);
    box-shadow: 0 1rem 4rem rgba(0,0,0,0.17);
    font-size: 1rem;
    &:hover, &:focus {
      background: var(--search-bg); } }
  &_label {
    width: 1rem;
    height: 1rem;
    position: absolute;
    left: 0.33rem;
    top: 0.25rem;
    opacity: 0.33;
    svg {
      width: 100%;
      height: 100%;
      fill: var(--text); } }
  &_result {
    padding: 0.5rem 1rem;
    display: block;
    &:not(.passive):hover {
      background-color: var(--theme);
      color: var(--light); }
    &.passive {
      display: grid; }
    &s {
      width: 13.5rem;
      background-color: var(--overlay);
      border-radius: 0 0 0.25rem 0.25rem;
      box-shadow: 0 1rem 4rem rgba(0,0,0,0.17);
      position: absolute;
      top: 125%;
      display: grid;
      overflow: hidden;
      z-index: 5;
      &:empty {
        display: none; } } }
  &_title {
    padding: 0.5rem 1rem 0.5rem 1rem;
    background: var(--theme);
    color: var(--light);
    font-size: 0.9rem;
    opacity: 0.87;
    text-transform: uppercase; } }

.button {
  background-color: var(--theme);
  color: var(--light);
  border-radius: 0.25rem;
  display: inline-block;
  padding: 0.75rem 1.25rem;
  text-align: center;
  &:hover {
    opacity: 0.84; }
  & + & {
    background-color: var(--haze);
    color: var(--dark); }
  &_grid {
    display: grid;
    max-width: 15rem;
    grid-gap: 1rem;
    grid-template-columns: repeat( auto-fit, minmax(12rem, 1fr) );
    @media  screen and (min-width: 557px) {
      max-width: 25rem; } }
  &.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  &.red {
    background-color: red;
    color: white; }
  &.blue {
    background-color: blue;
    color: white; }
  &.green {
    background-color: green;
    color: white; }
  &.purple {
    background-color: purple;
    color: white; } }

.video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 1.5rem 0;
  border-radius: 0.6rem;
  background-color: var(--bg);
  box-shadow: 0 1rem 2rem rgba(0,0,0,0.17);
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: none;
    position: absolute;
    transform: scale(1.02); } }
.icon {
  width: 1.1rem;
  height: 1.1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem; }

.link {
  opacity: 0;
  position: relative;
  &_owner:hover & {
    opacity: 1; }
  &_yank {
    opacity: 1;
    &ed {
      position: absolute;
      right: -2.2rem;
      top: -2rem;
      background-color: var(--theme);
      color: var(--light);
      width: 7rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.9rem;
      border-radius: 1rem;
      text-align: center;
      &::after {
        position: absolute;
        top: 1rem;
        content: "";
        border-color: var(--theme) transparent;
        border-style: solid;
        border-width: 1rem 1rem 0 1rem;
        height: 0;
        width: 0;
        transform-origin: 50% 50%;
        transform: rotate(145deg);
        right: 0.45rem; } } } }

.gallery {
  width: 100%;
  column-count: 3;
  column-gap: 1rem;
  @media screen and (max-width: 667px) {
    column-count: 2; }
  &_item {
    background-color: transparent;
    margin: 0 0 1rem; }
  &_image {
    margin: 0 auto; } }

.pager {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  margin: 2rem 0;
  max-width: 100vw;
  overflow: hidden;
  svg {
    filter: opacity(0.75);
    width: 1.25rem;
    height: 1rem;
    transform-origin: 50% 50%; }

  &_lean {
    justify-content: flex-end; }

  &_label {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

  &_link {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    width: 12.5rem;
    max-width: 40vw;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    &::before, &::after {
      background-image: var(--next-icon);
      height: 0.8rem;
      width: 0.8rem;
      background-size: 100%;
      background-repeat: no-repeat;
      transform-origin: 50% 50%; } }

  &_item {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 48%;
    // filter: opacity(0.87)
    &.prev {
      align-items: flex-start;
 }      // margin-right: 0.5rem

    &.next {
      align-items: flex-end;
      // margin-left: 0.5rem
      &::after {
        content: ""; } } }

  &_item.prev &_link {
    &::before {
      content: "";
      transform: rotate(180deg);
      margin-right: 0.67rem; } }

  &_item.next &_link {
    &::after {
      content: "";
      margin-left: 0.67rem; } }

  &_item.next &_link {
    grid-template-columns: 1fr 1.5rem; }

  &_meta {
    margin: 0.5rem 0; } }

.color {
  &_mode {
    margin-left: 1rem; }

  &_choice {
    outline: none;
    border: none;
    -webkit-appearance: none;
    height: 1rem;
    position: relative;
    width: 1rem;
    border-radius: 1rem;
    cursor: pointer;
    z-index: 2;
    right: 0;
    filter: contrast(0.8);

    &::after {
      content: "";
      top: 0.1rem;
      bottom: 0;
      left: 0;
      position: absolute;
      height: 1.3rem;
      background: var(--accent);
      width: 1.3rem;
      border-radius: 0.4rem;
      z-index: 3;
      background-image: var(--sun-icon);
      background-size: 60%;
      background-position: center;
      background-repeat: no-repeat; } }

  &_icon {
    height: 1rem;
    width: 1rem;
    margin: 0;
    z-index: 4;
    position: absolute;
    transform: translateY(-50%);
    transition: transform 0.5s cubic-bezier(.19,1,.22,1);
    right: 3.5rem; } }

.tip {
  padding: 1.5rem 1rem 1.5rem 1.5rem;
  margin: 1.5rem 0;
  border-left: 0.2rem solid var(--theme);
  position: relative;
  background: var(--accent);
  blockquote {
    padding: 0;
    margin: 0;
    border: none;
    &::before {
      display: none; } }
  p {
    &:first-child, ~ p {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0; } }
  &_warning {
    --theme: var(--inline-color); }
  &_warning::before {
    transform: rotate(180deg); }
  &::before {
    content: "";
    position: absolute;
    left: -0.85rem;
    top: 1.5rem;
    z-index: 3;
    padding: 0.75rem;
    transform-origin: 50% 50%;
    border-radius: 50%;
    background-color: var(--theme);
    background-image: var(--info-icon);
    background-size: 12%;
    background-position: 50% 50%;
    background-repeat: no-repeat; } }

.tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0 2rem 0;
  position: relative;

  &.tabs-left {
    justify-content: flex-start;

    label.tab-label {
      margin-right: 0.5rem; }

    .tab-content {
      border-radius: 0px 6px 6px 6px; } }

  &.tabs-right {
    justify-content: flex-end;

    label.tab-label {
      margin-left: 0.5rem; }

    .tab-content {
      border-radius: 6px 6px 6px 6px; } }

  input.tab-input {
    display: none; }

  label.tab-label {
    background-color: var(--accent) transparent;
    border-color: var(--theme);
    border-radius: 6px 6px 0px 0px;
    border-style: solid;
    border-bottom-style: hidden;
    border-width: 2px;
    cursor: pointer;
    display: inline-block;
    order: 1;
    padding: 0.3rem 0.6rem;
    position: relative;
    top: 2px;
    user-select: none; }

  input.tab-input:checked + label.tab-label {
    background-color: var(--accent);
    border-color: var(--theme); }

  .tab-content {
    background-color: var(--accent);
    border-color: var(--theme);
    border-style: solid;
    border-width: 2px;
    display: none;
    order: 2;
    padding: 1rem;
    width: 100%; } }


.button-center {
  display: block;
  margin: 0 auto;
  text-align: center; }

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-align: center; }

.small-image {
  width: 50%; /* Adjust the width as needed */; }


//banner content
.hero {
  position: relative;
  width: 100%;
  height: 400px;
  color: white;
  text-align: center;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 300px; }

  &-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover; }

  &-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    padding: 1rem;
    align-items: center;

    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      white-space: normal;
      word-wrap: break-word;

      @media (max-width: 768px) {
        font-size: 1.8rem; } }

    .hero-description {
      font-size: 1rem;
      margin-bottom: 1rem;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 768px) {
        font-size: 0.9rem;
        max-width: 90%; } }

    .transparent-button {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      background-color: var(--theme);
      color: rgba(255, 255, 255, 0.9);
      border: 2px solid var(--theme);
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: darken(rgb(52,199,89), 20%);
        color: white; }

      @media (max-width: 768px) {
        font-size: 0.9rem;
        padding: 0.4rem 0.8rem; } } } }

.pill {
  display: inline-block;
  padding: 0.1em 0.4em;
  border-radius: 0.75em;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  &.ttp {
    background-color: rgba(142, 142, 147, 0.2);
    color: rgb(142, 142, 147); }

  &.TTP {
    background-color: rgba(255, 69, 58, 0.2);
    color: rgb(255, 69, 58); }

  &.Anomaly {
    background-color: rgba(255, 159, 10, 0.2);
    color: rgb(255, 159, 10); }

  &.Correlation {
    background-color: rgba(48, 209, 88, 0.2);
    color: rgb(48, 209, 88); }

  &.Hunting {
    background-color: rgba(191, 90, 242, 0.2);
    color: rgb(191, 90, 242); } }

.icon-tiny {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  flex-shrink: 0;
  // Override general img styles
  margin: 0;
  padding: 0; }

// If needed, we can be even more specific:
img.icon-tiny {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 3px 0 0;
  padding: 0;
  flex-shrink: 0; }

.table-wrapper {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;

  @media screen and (max-width: 768px) {
    .detection-table, .stories-table, .playbooks-table, .data-sources-table {
      min-width: 800px; } } }

.detection-table, .stories-table, .playbooks-table, .data-sources-table {
  width: 100%;
  table-layout: fixed;

  th, td {
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding: 0.5rem; }

  @media screen and (max-width: 768px) {
    font-size: 14px;

    th, td {
      padding: 0.3rem; } } }

.detection-table {
  .col-0 {
    width: 25%; }
  .col-1 {
    width: 15%; }
  .col-2 {
    width: 15%; }
  .col-3 {
    width: 14%; }
  .col-4 {
    width: 18%; }
  .col-5 {
    width: 14%; }
  .col-6 {
    width: 13%; } }

.stories-table {
  .col-0 {
    width: 25%; }  // Name
  .col-1 {
    width: 20%; }  // Data Sources
  .col-2 {
    width: 15%; }  // Tactics
  .col-3 {
    width: 20%; }  // Products
  .col-4 {
    width: 0%; }  // Platform (hidden)
  .col-5 {
    width: 13%; } }  // Date

.data-sources-table {
  .col-0 {
    width: 28%; }  // Name
  .col-1 {
    width: 14%; }  // Platform
  .col-2 {
    width: 22%; }  // Sourcetype
  .col-3 {
    width: 20%; }  // Source
  .col-4 {
    width: 22%; } }  // Supported App

// Metadata Container
.metadata-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px; }

.metadata-square {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 14px;
  background-color: var(--accent);
  color: var(--text);
  border: 1px solid var(--border-color);

  &.date {
    background-color: #4CAF50;
    color: white; }
  &.id {
    background-color: #2196F3;
    color: white; }
  &.author {
    background-color: #9C27B0;
    color: white; }
  &.type {
    color: white !important;
    a {
      color: white !important; }
    &.TTP {
      background-color: rgb(255, 69, 58); }
    &.Anomaly {
      background-color: rgb(255, 159, 10); }
    &.Correlation {
      background-color: rgb(48, 209, 88); }
    &.Hunting {
      background-color: rgb(191, 90, 242); }
    &.Baseline {
      background-color: rgb(0, 122, 255); }
    &.Investigation {
      background-color: rgb(191, 90, 242); }
    &.Response {
      background-color: rgb(48, 209, 88); } }

  &.product {
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken(#ffffff, 10%);
      text-decoration: underline; } } }

// Framework Box
.detection-framework {
  border: 1px solid var(--border-color);
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .square {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    font-size: 14px;
    background-color: var(--accent);
    color: var(--text);
    border: 1px solid var(--border-color);

    &.mitre-technique {
      background-color: rgba(0, 102, 204, 0.2);
      color: rgb(0, 102, 204); }
    &.mitre-tactic {
      background-color: rgba(255, 102, 0, 0.2);
      color: rgb(255, 102, 0); }
    &.kill-chain {
      background-color: rgba(76, 175, 80, 0.2);
      color: rgb(76, 175, 80); }
    &.nist {
      background-color: rgba(156, 39, 176, 0.2);
      color: rgb(156, 39, 176); }
    &.cis {
      background-color: rgba(255, 152, 0, 0.2);
      color: rgb(255, 152, 0); }
    &.threat-actor {
      background-color: rgba(244, 67, 54, 0.2);
      color: rgb(244, 67, 54); }

    img {
      height: 16px;
      margin-right: 5px; }

    a {
      color: inherit;
      text-decoration: none; } } }

// Annotations

.mitre-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;

  th, td {
    border: 1px solid var(--border-color);
    padding: 8px;
    text-align: left; }

  th {
    background-color: var(--theme);
    color: var(--light);
    font-weight: bold; }

  tr:nth-child(even) {
    background-color: var(--accent); }

  tr:nth-child(odd) {
    background-color: var(--bg); }

  td {
    color: var(--text); }

  a {
    color: var(--theme);
    text-decoration: none;

    &:hover {
      text-decoration: underline; } } }

.framework-box {
  border: 1px solid var(--border-color);
  padding: 15px;
  margin-bottom: 20px;
  background-color: var(--bg); }

.framework-headers {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px; }

.section-header {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 0.75em;
  font-size: 14px;
  background-color: var(--accent);
  color: var(--text);

  &.active {
    background-color: var(--theme);
    color: var(--light); } }

.expand-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  font-weight: bold; }

.framework-section {
  display: none;
  margin-bottom: 10px;

  &.active {
    display: block; } }

.section-content {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; }

.pill {
  display: inline-block;
  padding: 0.1em 0.4em;
  border-radius: 0.75em;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  &.mitre-technique {
    background-color: rgba(0, 102, 204, 0.2);
    color: rgb(0, 102, 204); }
  &.mitre-tactic {
    background-color: rgba(255, 102, 0, 0.2);
    color: rgb(255, 102, 0); }
  &.kill-chain {
    background-color: rgba(76, 175, 80, 0.2);
    color: rgb(76, 175, 80); }
  &.nist {
    background-color: rgba(156, 39, 176, 0.2);
    color: rgb(156, 39, 176); }
  &.cis {
    background-color: rgba(255, 152, 0, 0.2);
    color: rgb(255, 152, 0); }
  &.threat-actor {
    background-color: rgba(255, 0, 0, 0.1);
    color: rgb(244, 67, 54);
    cursor: pointer; } }

// Mitre Coverage Table
.mitre-coverage-table {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem; }

.technique-item {
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: var(--bg);

  h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: var(--text); } }

.ttp-button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  background-color: var(--accent);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  cursor: pointer;
  position: relative;
  font-size: 0.9rem;
  color: var(--text); }

.coverage-dot {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.red {
    background-color: #ff0000; }
  &.yellow {
    background-color: #ffff00; }
  &.green {
    background-color: #00ff00; } }

.filter-container {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    input {
      width: 100%;
      margin-bottom: 0.5rem; }

    .filter-count {
      align-self: flex-end; } } }

.pill {
  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 2px 5px; } }

.sortable {
  @media screen and (max-width: 768px) {
    padding-right: 1.5rem;
    position: relative;

    .icon {
      position: absolute;
      right: 0.3rem;
      top: 50%;
      transform: translateY(-50%); } } }

td .pill {
  @media screen and (max-width: 768px) {
    display: inline-block;
    margin-bottom: 2px; } }
